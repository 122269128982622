// Fonts
$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

// Color
$primary-color: #516279;
$secondary-color: #333333;
$tertiary-color: #564FF2;
$section-title: #1D2340;

$primary-bg-color: #f5f5fc;

$common-blue-color: #2884FC;
$footer-text1: #9999DD;
$footer-bottom: #68258E;

// path
$images-path: '../images' !default;

// icomoon
$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../fonts/icomoon' !default;


$rv-arrow-right: "\e900";
$rv-email: "\e901";
$rv-facebook: "\e902";
$rv-instagram: "\e903";
$rv-linkedin: "\e904";
$rv-location: "\e905";
$rv-phone: "\e906";
$rv-twitter: "\e907";

