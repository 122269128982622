.btn {
  font-family: $secondary-font;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 2.5rem;
  padding: 1.2rem 2.2rem;
  background-image: linear-gradient(to right, #9452E4, #564FF2);
  border: 0.1rem solid transparent;
  transition: all 0.5s ease;
  color: #fff;
  text-transform: uppercase;
  @include breakpoint(medium-large-desktop-max) {
    font-size: 1.1rem;
    padding: 0.8rem 1.7rem;
  }
  &:hover,
  &:focus,
  &:active {
    background-image: linear-gradient(to right, #564FF2, #9452E4);
    background-color: transparent !important;
    color: $white;
  }
  &.btn-purple-override{
    background-image: unset;
    background-color: transparent;
    color: $tertiary-color;
    border: 0.1rem solid $tertiary-color;
    &:hover,
    &:focus,
    &:active {
      background-color: $tertiary-color !important;
      
      color: $white;
    }
  }
}
