.form-control {
  padding: 1rem 1.4rem;
  border-radius: 0.4rem;
  font-size: 1.3rem;
  height: auto;
  text-overflow: ellipsis;
  @include breakpoint(large-mac-desktop-min) {
    font-size: 2rem;
    padding: 1.5rem 2.2rem;
  }
  @include breakpoint(medium-desktop-max) {
    font-size: 1.2rem;
    padding: 0.8rem 1.2rem;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $primary-color;
    -webkit-text-fill-color: $primary-color !important;
    color: $primary-color;
  }
}

.form-group {
  margin-bottom: 2.5rem;
  @include breakpoint(large-mac-desktop-min) {
    margin-bottom: 3.5rem;
  }
  @include breakpoint(medium-desktop-max) {
    margin-bottom: 2rem;
  }
}