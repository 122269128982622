@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold/Montserrat-Bold.eot');
  src: url('../fonts/Montserrat-Bold/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Bold/Montserrat-Bold.woff2') format('woff2'), url('../fonts/Montserrat-Bold/Montserrat-Bold.woff') format('woff'),
    url('../fonts/Montserrat-Bold/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium/Montserrat-Medium.eot');
  src: url('../fonts/Montserrat-Medium/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-Medium/Montserrat-Medium.woff2') format('woff2'), url('../fonts/Montserrat-Medium/Montserrat-Medium.woff') format('woff'),
    url('../fonts/Montserrat-Medium/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-SemiBold/Montserrat-SemiBold.eot');
  src: url('../fonts/Montserrat-SemiBold/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff2') format('woff2'),
    url('../fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff') format('woff'),
    url('../fonts/Montserrat-SemiBold/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// Poppins
@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Bold/Poppins-Bold.eot');
  src: url('../fonts/Poppins-Bold/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Bold/Poppins-Bold.woff2') format('woff2'),
      url('../fonts/Poppins-Bold/Poppins-Bold.woff') format('woff'),
      url('../fonts/Poppins-Bold/Poppins-Bold.ttf') format('truetype'),
      url('../fonts/Poppins-Bold/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Regular/Poppins-Regular.eot');
  src: url('../fonts/Poppins-Regular/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Regular/Poppins-Regular.woff2') format('woff2'),
      url('../fonts/Poppins-Regular/Poppins-Regular.woff') format('woff'),
      url('../fonts/Poppins-Regular/Poppins-Regular.ttf') format('truetype'),
      url('../fonts/Poppins-Regular/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Medium/Poppins-Medium.eot');
  src: url('../fonts/Poppins-Medium/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Medium/Poppins-Medium.woff2') format('woff2'),
      url('../fonts/Poppins-Medium/Poppins-Medium.woff') format('woff'),
      url('../fonts/Poppins-Medium/Poppins-Medium.ttf') format('truetype'),
      url('../fonts/Poppins-Medium/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins-Light/Poppins-Light.eot');
  src: url('../fonts/Poppins-Light/Poppins-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Poppins-Light/Poppins-Light.woff2') format('woff2'),
      url('../fonts/Poppins-Light/Poppins-Light.woff') format('woff'),
      url('../fonts/Poppins-Light/Poppins-Light.ttf') format('truetype'),
      url('../fonts/Poppins-Light/Poppins-Light.svg#Poppins-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}





