.home-banner{
    margin-top: 12.82rem;
    margin-bottom: 9.5rem;
    @include breakpoint(medium-large-desktop-max){
        margin-top: 9.1rem;
        margin-bottom: 6.7rem;
    }
    @include breakpoint(mobile-max){
        margin-top: 6.5rem;
        margin-bottom: 4.6rem;
    }
    .home-banner-details{
        position: relative;
        @include breakpoint(mobile-max){
            display: flex;
            align-items: center;
            padding-bottom: 2.5rem;
        }
        .envision-image{
            position: absolute;
            bottom: calc(100% + 3rem);
            left: 50%;
            transform: translateX(-50%);
            img{
                @include breakpoint(medium-large-desktop-max){
                    max-width: 11.8rem;
                }
                @include breakpoint(mobile-max){
                    max-width: 8.4rem;
                }
            }
            @include breakpoint(mobile-max){
                position: static;
                transform: unset;
            }
        }
        .home-banner-text{
            margin-top: 5.1rem;
            @include breakpoint(mobile-max){
                margin-top: 0;
            }
        }
    }
    .banner-image-wrap{
        position: relative;
        .orange-hex-1{
            top: -2.5rem;
            left: -2.8rem;
            width: 4.5rem;
            height: 5rem;
            background-image: url("../images/shapes/orange-hex1.svg");
            z-index: -1;
            @include breakpoint(mobile-max){
                width: 3.2rem;
                height: 3.55rem;
                top: -1.8rem;
                left: 3.8rem;
            }
        }
        .purple-hex-1{
            top: 0rem;
            left: -8.5rem;
            width: 2.25rem;
            height: 2.5rem;
            background-image: url("../images/shapes/purple-hex1.svg");
            @include breakpoint(mobile-max){
                width: 1.6rem;
                height: 1.8rem;
                left: 0rem;
            }
        }
        .light-blue-hex-1{
            top: 98%;
            right: -20rem;
            width: 8.1rem;
            height: 8.1rem;
            background-image: url("../images/shapes/light-blue-hex1.svg");
            z-index: -1;
            @include breakpoint(mobile-max){
                width: 5.8rem;
                height: 5.8rem;
                right: 6rem;
            }
        }
        .banner-img{
            margin-left: -11rem;
            // @include breakpoint(medium-large-desktop-max){
            //     max-width: 70.6rem;
            //     margin-left: 5rem;
            // }
            // @include breakpoint(desktop-max){
            //     margin-left: -5rem;
            // }
            // @include breakpoint(ipad-max){
            //     margin-left: -11rem;
            // }
            @include breakpoint(mobile-max){
                margin-left: 0;
                max-width: 100%;
            }
        }
    }
    .banner-envision-details{
        margin-top: 9.9rem;
        position: relative;
        @include breakpoint(medium-large-desktop-max){
            margin-top: 7rem;
        }
        p{
            color: $primary-color;
            margin-bottom: 2rem;
            @include breakpoint(medium-large-desktop-max){
               margin-bottom: 1rem;
            }
        }
        .green-hex-1{
            bottom: 100%;
            right: calc(100% + 3rem);
            width: 4.5rem;
            height: 5rem;
            background-image: url("../images/shapes/green-hex1.svg");
            @include breakpoint(mobile-max){
                width: 3.2rem;
                height: 3.5rem;
                right: calc(100% + 2.1rem);
            }
        }
    }
}
.uncover-opportunites{
    padding: 8.7rem 0 3.1rem 0;
    background-image: linear-gradient(to bottom right, #68258E, #411D87, #0E1280, #0E1280);
    color: $white;
    position: relative;
    @include breakpoint(mobile-max){
        padding: 6.2rem 0 3.1rem 0;
    }
    .orange-hex-2{
        top: -8rem;
        left: -6rem;
        width: 11.7rem;
        height: 13rem;
        background-image: url("../images/shapes/orange-hex2.svg");
        @include breakpoint(mobile-max){
            width: 8.3rem;
            height: 9.2rem;
            top: -6rem;
            left: -4rem;;
        }
    }
    .dark-blue-hex-1{
        top: -28rem;
        right: -20rem;
        width: 55.9rem;
        height: 55.9rem;
        background-image: url("../images/shapes/dark-blue-hex1.svg");
        @include breakpoint(medium-large-desktop-max){
            width: 39.7rem;
            height: 39.7rem;
            top: -20rem;
            right: -14rem;
        }
        @include breakpoint(mobile-max){
            width: 20rem;
            height: 20rem;
            top: -10rem;
            right: -7rem;
        }
    }
    .vertical-grey-dots{
        top: 1rem;
        right: -1rem;
        @include breakpoint(mobile-max){
            top: 1rem;
            right: -1rem;
        }
    }
    .dark-blue-grad-hex-2{
        top: 3rem;
        right: 9rem;
        width: 37.2rem;
        height: 32rem;
        background-image: url("../images/shapes/dark-blue-grad-hex2.svg");
        @include breakpoint(mobile-max){
            width: 26.4rem;
            height: 23rem;
            top: 0rem;
            right: 1rem;
        }
    }
    .right-white-wave{
        top: 8.5rem;
        left: -4rem;
        width: 68.2rem;
        height: 167.2rem;
        background-image: url("../images/shapes/right-white-wave.svg");
        z-index: -1;
        @include breakpoint(mobile-max){
            width: 48.4rem;
            height: 118.7rem;
            top: 8.5rem;
            left: -4rem;
        }
    }
    .uncover-heading-wrap{
        position: relative;
        z-index: 1;
        margin-bottom: 7.8rem;
        @include breakpoint(medium-large-desktop-max){
            margin-bottom: 5.5rem;
        }
        @include breakpoint(mobile-max){
            margin-bottom: 2rem;
        }
        p{
            font-size: 2.4rem;
            font-weight: 500;
            margin-bottom: 3rem;
            &:last-of-type{
                margin-bottom: 0;
            }
            @include breakpoint(medium-large-desktop-max){
                font-size: 1.7rem;
                margin-bottom: 2.1rem;
            }
            @include breakpoint(mobile-max){
                margin-bottom: 1.5rem;
            }
        }
    }
    .uncover-content-wrap{
        position: relative;
        .uncover-image-wrap{
            position: absolute;
            top: 4rem;
            left: calc(100% - 100rem);
            @include breakpoint(mobile-max){
                position: static;
                margin-bottom: 2rem;
            }
            img{
                max-width: 137.5rem;
                box-shadow: 0 0 5rem 0 rgba($color: #564FF2, $alpha: 0.5);
                border-radius: 2rem;
                @include breakpoint(mobile-max){
                    max-width: 100%;
                }
            }
        }
    }
}
.discovers-wrap{
    position: relative;
    .row{
        [class*="col"]{
            padding-bottom: 16rem;
            @include breakpoint(mobile-max){
                padding-bottom: 4rem;
            }
        }
    }
    &.discovers-wrap-1{
        padding-top: 72rem;
        @include breakpoint(medium-large-desktop-min){
            padding-top: 53rem;
        }
        @include breakpoint(desktop-max){
            padding-top: 65rem;
        }
         @include breakpoint(ipad-max){
            padding-top: 49rem;
        }
        @include breakpoint(mobile-max){
            padding-top: 5rem;
        }
        .row{
            [class*="col"]{
                position: relative;
                &:nth-child(1){
                    .orange-hex-4{
                        bottom: 100%;
                        right: 100%;
                        width: 3.7rem;
                        height: 4.2rem;
                        background-image: url("../images/shapes/orange-hex4.svg");
                        z-index: -1;
                        @include breakpoint(mobile-max){
                            width: 2.6rem;
                            height: 3rem;
                        }
                    }
                    .vertical-purple-dots{
                        top: 14rem;
                        right: calc(100% + 6.3rem);
                        @include breakpoint(medium-large-desktop-max){
                            top: 7rem;
                        }
                    }
                }
                &:nth-child(2){
                    .discovers-image-wrap{
                        .purple-hex-2{
                            top: -18rem;
                            left: -8rem;
                            width: 32.8rem;
                            height: 36.4rem;
                            background-image: url("../images/shapes/purple-hex2.svg");
                            z-index: -1;
                            @include breakpoint(ipad-max){
                                top: -8rem;
                                left: -7rem;
                                width: 23.2rem;
                                height: 25.8rem;
                            }
                            @include breakpoint(mobile-max){
                                width: 11.6rem;
                                height: 13rem;
                                top: -4rem;
                                left: -2rem;
                            }
                        }
                    }
                }
                &:nth-child(4){
                    &::before{
                        content: "";
                        position: absolute;
                        top: 24rem;
                        left: -35rem;
                        width: 59.5rem;
                        height: 0.3rem;
                        background-color: rgba($color: #A917EF, $alpha: 0.3);
                        transform: rotate(-59deg);
                        z-index: -1;
                        @include breakpoint(mobile-max){
                            width: 42rem;
                            top: 0;
                            left: 0;
                        }
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        top: 29rem;
                        left: -43rem;
                        width: 59.5rem;
                        height: 0.1rem;
                        background-color: rgba($color: #A917EF, $alpha: 0.3);
                        transform: rotate(-59deg);
                        z-index: -1;
                        @include breakpoint(mobile-max){
                            width: 42rem;
                            top: 5rem;
                            left: -5rem;
                        }
                    }
                }
                &:nth-child(5){
                    .horizontal-purple-dots{
                        bottom: calc(100% + 6rem);
                        right: calc(50%);
                        z-index: -1;
                        @include breakpoint(mobile-max){
                            right: 70%;
                            bottom: 100%;
                        }
                    }
                }
                &:nth-child(6){
                    .discovers-image-wrap{
                        .purple-hex-3{
                            top: -8rem;
                            left: 50%;
                            width: 17.9rem;
                            height: 19.8rem;
                            background-image: url("../images/shapes/purple-hex3.svg");
                            z-index: -1;
                            @include breakpoint(mobile-max){
                                top: -5rem;
                                width: 12.7rem;
                                height: 14rem;
                            }
                        }
                        .orange-hex-5{
                            top: -7.5rem;
                            left: 75%;
                            width: 6.3rem;
                            height: 7rem;
                            background-image: url("../images/shapes/orange-hex5.svg");
                            z-index: -1;
                            @include breakpoint(desktop-max){
                                left: 80%;
                            }
                            @include breakpoint(ipad-max){
                                left: 90%;
                            }
                            @include breakpoint(mobile-max){
                                width: 4.4rem;
                                height: 4.9rem;
                                top: -5rem;
                            }
                        }
                    }   
                }
            }
        }
        .dark-blue-hex-3{
            top: 0;
            right: -4rem;
            width: 7.9rem;
            height: 8.7rem;
            background-image: url("../images/shapes/dark-blue-hex3.svg");
            @include breakpoint(mobile-max){
                width: 5.6rem;
                height: 6.2rem;
                right: -3rem;
            }
        }
        .orange-hex-3{
            top: 10.4rem;
            right: 6rem;
            width: 2.1rem;
            height: 2.3rem;
            background-image: url("../images/shapes/orange-hex3.svg");
            @include breakpoint(mobile-max){
                width: 1.5rem;
                height: 1.6rem;
                right: 5rem;
            }
        }
        .left-purple-wave{
            top: 15.6rem;
            right: -4rem;
            width: 68.7rem;
            height: 195.2rem;
            background-image: url("../images/shapes/left-purple-wave.svg");
            @include breakpoint(mobile-max){
                width: 34rem;
                height: 98rem;
            }
        }
        .right-purple-wave{
            top: calc(100% - 109rem);
            left: 0;
            width: 106.5rem;
            height: 343.8rem;
            background-image: url("../images/shapes/right-purple-wave.svg");
            z-index: -1;
            @include breakpoint(mobile-max){
                width: 53.6rem;
                height: 173rem;
            }
        }
        .yellow-hex-1{
            top: calc(100% - 38rem);
            left: -2.5rem;
            width: 4.8rem;
            height: 5.3rem;
            background-image: url("../images/shapes/yellow-hex1.svg");
            @include breakpoint(mobile-max){
                width: 3.4rem;
                height: 3.7rem;
                left: -2rem;
                top: calc(100% - 27rem);
            }
        }
        .dark-blue-hex-4{
            top: calc(100% - 20rem);
            left: -2.5rem;
            width: 4.5rem;
            height: 5rem;
            background-image: url("../images/shapes/dark-blue-hex4.svg");
            @include breakpoint(mobile-max){
                width: 3.2rem;
                height: 3.5rem;
                left: -2rem;
                top: calc(100% - 14rem);
            }
        }
    }
    &.discovers-wrap-2{
        .sub-section{
            p{
                line-height: 4.2rem;
                @include breakpoint(medium-large-desktop-max){
                    line-height: 3rem;
                }
            }
        }
        .discovers-image-wrap{
            &.discovers-image-wrap-left-laptop{
                img{
                    box-shadow: 0 0 5rem 0 rgba($color: #564FF2, $alpha: 0.5);
                    border-radius: 2rem;
                    margin-left: -23rem;
                    max-width: 117.1rem;
                    @include breakpoint(medium-mac-desktop-min){
                        margin-left: -10rem;
                    }
                    @include breakpoint(desktop-max){
                        margin-left: -37rem;
                    }
                    @include breakpoint(ipad-max){
                        margin-left: -53rem;
                    }
                    @include breakpoint(mobile-max){
                        margin-left: 0;
                        max-width: 100%;
                    }
                }
            }
        }
        .left-white-wave{
            top: 72rem;
            right: 0rem;
            width: 72.8rem;
            height: 178.5rem;
            background-image: url("../images/shapes/left-white-wave.svg");
            z-index: -1;
            @include breakpoint(ipad-max){
                width: 51.6rem;
                height: 126.7rem;
            }
            @include breakpoint(mobile-max){
                width: 36.6rem;
                height: 89.9rem;
            }
        }
        .light-blue-hex-2{
            top: 56rem;
            right: -12rem;
            width: 23.5rem;
            height: 26rem;
            background-image: url("../images/shapes/light-blue-hex2.svg");
            @include breakpoint(ipad-max){
                width: 16.6rem;
                height: 18.4rem;
                right: -9rem;
                top: 60rem;
            }
            @include breakpoint(mobile-max){
                width: 11.7rem;
                height: 13rem;
                right: -6rem;
            }
        }
        .green-hex-2{
            top: 69rem;
            right: 14rem;
            @include breakpoint(ipad-max){
                top: 70rem;
                right: 12rem;
            }
            @include breakpoint(mobile-max){
                top: 69rem;
                right: 6rem;
            }
        }
        .row{
            [class*="col"]{
                position: relative;
                &:nth-child(4){
                    .purple-hex-4{
                        top: 6rem;
                        right: -12rem;
                        width: 13.2rem;
                        height: 14.6rem;
                        background-image: url("../images/shapes/purple-hex4.svg");
                        @include breakpoint(ipad-max){
                            width: 9.3rem;
                            height: 10.3rem;
                            top: 10rem;
                            right: -10rem;
                        }
                        @include breakpoint(mobile-max){
                            width: 6.6rem;
                            height: 7.3rem;
                            top: 6rem;
                            right: -5rem;
                        }
                    }
                    &::before{
                        content: "";
                        position: absolute;
                        top: 47rem;
                        left: -42rem;
                        width: 59.5rem;
                        height: 0.3rem;
                        background-color: rgba($color: #A917EF, $alpha: 0.3);
                        transform: rotate(-38deg);
                        z-index: -1;
                        @include breakpoint(mobile-max){
                            width: 42rem;
                            top: 0;
                            left: 0;
                        }
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        top: 51rem;
                        left: -52rem;
                        width: 59.5rem;
                        height: 0.1rem;
                        background-color: rgba($color: #A917EF, $alpha: 0.3);
                        transform: rotate(-38deg);
                        z-index: -1;
                        @include breakpoint(mobile-max){
                            width: 42rem;
                            top: 5rem;
                            left: -3rem;
                        }
                    }
                }
                &:nth-child(6){
                    .horizontal-purple-dots{
                        bottom: 10rem;
                        left: -1rem;
                        z-index: -1;
                    }
                    .purple-hex-5{
                        bottom: -3rem;
                        left: 37rem;
                        width: 13.2rem;
                        height: 14.6rem;
                        background-image: url("../images/shapes/purple-hex5.svg");
                        z-index: -1;
                    }
                }
            }
        }
    }
    .discovers-text{
        line-height: 4.2rem;
        margin-bottom: 0;
        @include breakpoint(medium-large-desktop-max){
            line-height: 3rem;
        }
    }
    .discovers-image-wrap{
        img{
            margin-left: 2rem;
            box-shadow: 0 0 5rem 0 rgba($color: #564FF2, $alpha: 0.5);
            border-radius: 2rem;
            @include breakpoint(mobile-max){
                margin: 0 auto;
                max-width: 100%;
            }
        }
        &.discovers-image-wrap-left-laptop{
            @include breakpoint(mobile-max){
                margin-bottom: 2rem;
            }
            img{
                margin-left: -35rem;
                max-width: 117.1rem;
                box-shadow: unset;
                @include breakpoint(medium-mac-desktop-min){
                    margin-left: -22rem;
                }
                @include breakpoint(desktop-max){
                    margin-left: -50.5rem;
                }
                @include breakpoint(ipad-max){
                    margin-left: -66rem;
                }
                @include breakpoint(mobile-max){
                    margin-left: 0;
                    max-width: 100%;
                }
            }
        }
    }
}
.customer-retention{
    background-image: url("../images/section4-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 10rem;
    padding-bottom: 11.5rem;
    margin-bottom: 13.7rem;
    @include breakpoint(mobile-max){
        padding-top: 5rem;
        padding-bottom: 5rem;
        margin-bottom: 5rem;
    }
    .customer-retention-purple{
        max-width: 133rem;
        margin: 0 auto;
        border-radius: 6.1rem;
        border: 0.3rem solid $white;
        background-image: linear-gradient(to right, #66258E, #0E1280);
        padding: 13.2rem 2.6rem 0 2.6rem;
        @include breakpoint(ipad-max){
            padding: 7.2rem 2.6rem 0 2.6rem;
        }
        @include breakpoint(mobile-max){
            padding: 3rem 1.5rem;
        }
        .customer-image-wrap{
            @include breakpoint(mobile-max){
                margin-bottom: 2rem;
            }
            img{
                margin-left: -25rem;
                max-width: 116.2rem;
                @include breakpoint(medium-desktop-max){
                    margin-left: -33rem;
                }
                @include breakpoint(desktop-max){
                    margin-left: -48rem;
                }
                @include breakpoint(ipad-max){
                    margin-left: -53rem;
                }
                @media (max-width: 870px){
                    margin-left: -63rem;
                }
                @include breakpoint(mobile-max){
                    margin-left: 0;
                    max-width: 100%;
                }
            }
        }
        .sub-section{
            p{
                color: $white;
                line-height: 4.1rem;
                @include breakpoint(medium-large-desktop-max){
                    line-height: 2.9rem;
                }
            }
        }
    }
}