.heading-1{
    font-size: 8rem;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(to right, #64248C, #0F117F);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    @include breakpoint(medium-large-desktop-max){
        font-size: 4.6rem;
    }
    @include breakpoint(ipad-max){
        font-size: 3.6rem;
    }
    @include breakpoint(mobile-max) {
        font-size: 2.7rem;
    }
}
.heading-2{
    font-size: 6rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #564FF2;
    margin-bottom: 0;
    @include breakpoint(medium-large-desktop-max){
        font-size: 4.3rem;
    }
    @include breakpoint(ipad-max){
        font-size: 3.3rem;
    }
    @include breakpoint(mobile-max) {
        font-size: 2.5rem;
    }
}
.heading-3{
    font-size: 5.8rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #516279;
    margin-bottom: 0;
    @include breakpoint(medium-large-desktop-max){
        font-size: 4.1rem;
    }
    @include breakpoint(ipad-max){
        font-size: 3.1rem;
    }
    @include breakpoint(mobile-max) {
        font-size: 2.2rem;
    }
}
.hex-shape{
    display: block;
    position: absolute; 
    background-repeat: no-repeat;
    background-size: cover;
}
.vertical-grey-dots{
    width: 8rem;
    height: 36.1rem;
    background-image: url("../images/vertical-grey-dots.png");
    @include breakpoint(mobile-max){
        width: 5.7rem;
        height: 26rem;
    }
}
.vertical-purple-dots{
    width: 8rem;
    height: 36.1rem;
    background-image: url("../images/vertical-purple-dots.png");
    @include breakpoint(mobile-max){
        width: 5.6rem;
        height: 25.6rem;
    }
}
.horizontal-purple-dots{
    width: 36.1rem;
    height: 8rem;
    background-image: url("../images/horizontal-purple-dots.png");
    @include breakpoint(mobile-max){
        width: 25rem;
        height: 5.8rem;
    }
}
.green-hex-2{
    width: 16.5rem;
    height: 18.3rem;
    background-image: url("../images/shapes/green-hex2.svg");
    @include breakpoint(ipad-max){
        width: 11.7rem;
        height: 12.9rem;
    }
    @include breakpoint(mobile-max){
        width: 8.3rem;
        height: 9.1rem;
    }
}
.sub-section{
    p{
        font-family: $secondary-font;
        font-weight: bold;
        margin-bottom: 2.3rem;
        + p{
            font-family: $primary-font;
            font-weight: normal;
            margin-bottom: 0;
        }
    }
}
.section-title {
    color: $section-title;
    font-size: 4.8rem;
    font-weight: bold;
    font-family: $secondary-font;
    text-transform: uppercase;
    @include breakpoint(medium-large-desktop-max){
        font-size: 3.4rem;
    }
    span {
        color: $tertiary-color;
    }
}
.section-description {
    color: #2B3150;
    font-size: 2.1rem;
    @include breakpoint(medium-large-desktop-max){
        font-size: 1.5rem;
    }
}
.section-header{
    margin: 14.6rem auto 0 auto;
    @include breakpoint(medium-large-desktop-max){
        margin: 10.3rem auto 0 auto;
    }
    .section-title{
        text-align: center;
        margin: 0 0 4.8rem;
        @include breakpoint(medium-large-desktop-max){
            margin: 0 0 3.4rem;
        }
        + .section-description{
            text-align: center;
            margin: 0 auto 4.9rem;
            @include breakpoint(medium-large-desktop-max){
                margin: 0 auto 3.5rem;
            }
        }
    }
}

