html {
  font-size: 10px;
  height: 100%;
}
#root {
  height: 100%;
}
// 1Rem = 10px the reason why we have mention 10px in HTML.
body {
  font-family: $primary-font;
  font-size: 2.1rem;
  background-color: $primary-bg-color;
  @include breakpoint(medium-large-desktop-max){
    font-size: 1.4rem;
  }
}
a {
  &:hover {
    text-decoration: none;
  }
}
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.wrapper {
  overflow: hidden;
}

.main {
  @include breakpoint(mobile-max) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.modal {
  padding: 0 !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #c8c8e2 #f1f1f1;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background-color: #c8c8e2;
  border-radius: 20px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 1rem;
  padding-left: 1rem;
}

.custom-container {
  margin: 0 auto;
  padding: 0;
  @include breakpoint(portrait-mobile-max){
    padding: 0 1.5rem;
  }
  @include breakpoint(mobile-min){
    max-width: 540px;
    padding: 0 1.5rem;
  }
  @include breakpoint(ipad-min){
    max-width: 720px;
  }
  @include breakpoint(desktop-min){
    max-width: 960px;
  }
  @include breakpoint(large-desktop-min){
    max-width: 1170px;
  }
  @include breakpoint(medium-mac-desktop-min) {
    max-width: 1400px;
  }
}