.careers-header{
    &.section-header{
        .section-description{
            margin: 0 auto 8rem;
            @include breakpoint(medium-large-desktop-max){
                margin: 0 auto 5.6rem;
            }
        }
    }
}
.careers-job-opening {
    position: relative;
    padding: 0 0 9.3rem 0;
    @include breakpoint(mobile-max){
        padding: 0 0 5.6rem 0;
    }
    .careers-job-list-headers {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 1.7rem 0;
        p {
            color: $tertiary-color;
            font-size: 2.1rem;
            font-weight: 500;
            margin: 0;
            @include breakpoint(mobile-max){
                font-size: 1.5rem;
            }
        }
        .careers-job-list-pagination{
            display: flex;
            align-items: center;
        }
    }
    .careers-job-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: flex;
            align-items: center;
            border-radius: 1rem;
            background-color: #FFFFFF;
            box-shadow: 0 0.51rem 2rem rgba(0, 0, 0, .24);
            padding: 1.35rem 4.8rem;
            @include breakpoint(ipad-max){
                padding: 1.35rem 3.4rem;
            }
            @include breakpoint(mobile-max){
                flex-wrap: wrap;
                padding: 1.35rem;
            }
        }
        li + li {
            margin-top: 0.8rem;
        }
        h6 {
            font-family: $secondary-font;
            font-size: 2.1rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            color: rgba($color: $section-title, $alpha: 0.88);
            margin-bottom: 0;
            margin-right: 3.25rem;
            flex-basis: 42%;
            @include breakpoint(medium-large-desktop-max){
                font-size: 1.5rem;
                flex-basis: 20%;
            }
            @include breakpoint(desktop-max){
                flex-basis: 33%;
            }
            @include breakpoint(ipad-max){
                flex-basis: 70%;
                margin-right: 2.3rem;
            }
            @include breakpoint(mobile-max){
                flex-basis: unset;
                display: inline-block;
                margin-right: 0;
                padding-bottom: 0.5rem;
                border-bottom: 0.1rem solid rgba(153, 153, 221, .27);
                margin-bottom: 1rem;
            }
        }
        p {
            color: #516279;
            font-size: 2.1rem;
            border-left: 0.1rem solid rgba(153, 153, 221, .27);
            padding: 0.7rem 0 0.7rem 2.6rem;
            margin: 0;
            flex-basis: 70%;
            @include breakpoint(medium-large-desktop-max){
                font-size: 1.5rem;
                flex-basis: 86%;
            }
            @include breakpoint(desktop-max){
                flex-basis: 75%;
            }
            @include breakpoint(ipad-max){
                flex-basis: 70%;
                padding:  0.7rem 0 0.7rem 1.8rem;
            }
            @include breakpoint(mobile-max){
                padding: 0;
                border-left: 0;
                margin-right: 1rem;
            }
        }
        .btn{
            white-space: nowrap;
        }
        &-pagination {
            &__previous,
            &__next {
                display: inline-block;
                border-radius: 1.5rem;
                background-color: white;
                padding: 1rem 1.7rem;
                @include breakpoint(mobile-max){
                    padding: 1rem 1.2rem;
                }
                img {
                    display: block;
                    @include breakpoint(mobile-max){
                        max-width: 1.6rem;
                    }
                }
                &.active {
                    position: relative;
                    background-color: #9452E4;
                    box-shadow: 0 3px 10px rgba(0, 0, 0, .24);
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(148, 82, 228, .51);
                        filter: blur(12px);
                        z-index: -1;
                    }
                }
            }
            &__next {
                margin-left: 2.4rem;
                @include breakpoint(mobile-max){
                    margin-left: 1.8rem;
                }
            }
        }
    }
    .bottom-right-green {
        bottom: 0;
        right: -41px;
        width: 83px;
        height: 92px;
        background-image: url("#{$images-path}/shapes/green-hex2.svg");
        z-index: -1;
    }
    .bottom-right-purple {
        bottom: 138px;
        right: -12px;
        width: 36px;
        height: 40px;
        background-image: url("#{$images-path}/shapes/purple-hex1.svg");
        opacity: .38;
        z-index: -1;
    }
}

.careers-thankyou {
    position: relative;
    padding: 8.9rem 0 3.5rem;
    @include breakpoint(mobile-max){
        padding: 5.6rem 0;
    }
    .row{
        [class*="col"]{
            position: relative;
            @include breakpoint(mobile-max){
                padding-bottom: 3rem;
                &:last-of-type{
                    padding-bottom: 0;
                }
            }
        }
    }
    .green-hex-2{
        bottom: calc(100% + 3.5rem);
        right: calc(95%);
        width: 7rem;
        height: 7.8rem;
        opacity: .38;
    }
    .vertical-purple-dots{
        top: 8rem;
        right: calc(100% + 5.5rem);
        z-index: -1;
    }
    .vertical-grey-dots{
        bottom: -9rem;
        right: 0;
    }
    .bottom-left {
        bottom: -8rem;
        left: 0;
        width: 9.1rem;
        height: 12.2rem;
        background-image: url("#{$images-path}/career/career-bottom-left.svg");
        z-index: -1;
    }
    .bottom-right {
        bottom: -15.1rem;
        right: 0;
        width: 7.4rem;
        height: 10.7rem;
        background-image: url("#{$images-path}/career/career-bottom-right.svg");
        opacity: 1;
        z-index: -1;
    }
    &__title {
        margin: 0 0 1.07rem 0;
    }
    &__dont-text {
        margin: 4.17rem 0 1.95rem 0;
        @include breakpoint(mobile-max){
            margin: 2.9rem 0 1.95rem 0;
        }
    }
    &__resume-text{
        a{
            color: #8D20A1;
        }
    }
}