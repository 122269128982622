.about-cds-header{
    .section-title + .section-description {
        max-width: 108.2rem;
    }
}
.about-cds {
    max-width: 160rem;
    margin: 0 auto;
    &-carousel {
        &.swiper{
            @include breakpoint(mobile-max){
                margin: 0 1.5rem;
            }
            .swiper-wrapper{
                align-items: center;
                margin-left: -3rem;
                @include breakpoint(mobile-max){
                    margin-left: -0.89rem;
                }
                @media (min-width: 1600px){
                    margin-left: -2rem;
                }
            }
        }
        .item {
            position: relative;
            text-align: center;
            border-radius: 1rem;
            background: rgb(14,18,128);
            background: linear-gradient(0deg, rgba(14,18,128,1) 0%, rgba(14,18,128,1) 30%, rgba(65,29,135,1) 60%, rgba(104,37,142,1) 100%);
            padding: 5.05rem 6.16rem 9.77rem;
            @include breakpoint(medium-large-desktop-max){
                padding: 3.58rem 4.3rem 6.8rem;
            }
            @media (min-width: 1550px){
                min-width: 50.52rem;
                max-width: 50.52rem;
            }
            &:after {
                content: '';
                position: absolute;
                top: 0.25rem;
                left: 0.25rem;
                width: calc(100% - 0.5rem);
                height: calc(100% - 0.5rem);
                border-radius: 1rem;
                border: 1.36rem solid #F5F5FC;
            }
            &.swiper-slide-active{
                padding: 7.52rem 6.54rem 8.98rem;
                @include breakpoint(medium-large-desktop-max){
                    padding: 5.3rem 4.6rem 6.3rem;
                }
                @media (min-width: 1550px){
                    min-width: 63rem;
                    max-width: 63rem;
                }
                .rounded-icon {
                    margin: 0 auto 3.6rem;
                    width: 9rem;
                    height: 9rem;
                    @include breakpoint(medium-large-desktop-max){
                        width: 6.4rem;
                        height: 6.4rem;
                        margin: 0 auto 2.5rem;
                    }
                    img{
                        width: 4.8rem;
                        height: 4.8rem;
                        @include breakpoint(medium-large-desktop-max){
                            width: 3.4rem;
                            height: 3.4rem;
                        }
                    }
                }
                .about-cds-carousel__title {
                    font-size: 2.4rem;
                    margin: 0 0 3.4rem;
                    @include breakpoint(medium-large-desktop-max){
                        font-size: 1.7rem;
                        margin: 0 0 2.4rem;
                    }
                }
                .section-description {
                    font-size: 2.1rem;
                    line-height: 3rem;
                    @include breakpoint(medium-large-desktop-max){
                        font-size: 1.5rem;
                        line-height: 2.1rem;
                    }
                }
            }
        }
        .item + .item {
            margin: 0 0.87rem;
        }
        &__title {
            color: $white;
            font-size: 2.1rem;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0 0 2.5rem 0;
            font-family: $secondary-font;
            @include breakpoint(medium-large-desktop-max){
                font-size: 1.5rem;
                margin: 0 0 1.7rem 0;
            }
        }
        .section-description {
            font-size: 1.6rem;
            line-height: 2.7rem;
            margin: 0;
            @include breakpoint(medium-large-desktop-max){
                font-size: 1.1rem;
                line-height: 1.9rem;
            }
        }
        .rounded-icon {
            margin: 0 auto 1.88rem;
            @include breakpoint(medium-large-desktop-max){
                margin: 0 auto 1.3rem;
            }
        }
    }
}


// global css



.rounded-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 8rem;
    height: 8rem;
    @include breakpoint(medium-large-desktop-max){
        width: 5.6rem;
        height: 5.6rem;
    }
    img {
        position: relative;
        width: 4.28rem;
        height: 4.28rem;
        z-index: 1;
        @include breakpoint(medium-large-desktop-max){
            width: 3rem;
            height: 3rem;
        }
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 38px;
        background-color: #19138C;
        box-shadow: 0 1rem 1.4rem 0 rgba(#19138C, 0.51);
    }
    &.orange-icon-bg{
        &::after{
            background-color: #F26522;
            box-shadow: 0 0.3rem 2.4rem 0 rgba($color: #F26522, $alpha: 0.16);
        }
    }
    &.purple-icon-bg{
        &::after{
            background-color: #722999;
            box-shadow: 0 0.3rem 2rem 0 rgba($color: #722999, $alpha: 0.24);
        }
    }
}


.about-values {
    position: relative;
    padding: 27.8rem 0 22.3rem 0;
    margin-bottom: 3.65rem;
    @include breakpoint(mobile-max){
        padding: 19.7rem 0 15.8rem 0;
    }
    .row{
        [class*="col"]{
            @include breakpoint(mobile-max){
                padding-bottom: 2rem;
                &:last-of-type{
                    padding-bottom: 0;
                }
            }
        }
    }
    .top-right {
        top: 0;
        right: 0;
        width: 212px;
        height: 298px;
        background-image: url("#{$images-path}/about/values-top-right.svg");
        @include breakpoint(mobile-max){
            width: 15rem;
            height: 21rem;
        }
    }
    .bottom-left {
        bottom: 4.8rem;
        left: 0;
        width: 81px;
        height: 162px;
        background-image: url("#{$images-path}/about/values-bottom-left.svg");
        @include breakpoint(mobile-max){
            width: 5.75rem;
            height: 11.5rem;
            bottom: 3.4rem;
        }
    }
    .light-green-blur {
        bottom: calc(100% + 6.25rem);
        left: calc(50% + 3.7rem);
        width: 8.14rem;
        height: 8.14rem;
        background-image: url("#{$images-path}/about/light-green-blur-hex.png");
        @include breakpoint(mobile-max){
            width: 5.7rem;
            height: 5.7rem;
            left: 30%;
        }
    }
    .horizontal-purple-dots{
        bottom: 0;
        left: 0;
    }
    .vertical-purple-dots{
        right: calc(100% + 4rem);
        top: -13rem;
    }
    .section-title {
        margin: 0 0 4.8rem 0;
        @include breakpoint(mobile-max){
            margin: 0 0 3.4rem 0;
        }
    }
}

.about-partners {
    @include breakpoint(ipad-max){
        margin-bottom: 5rem;
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        @include breakpoint(portrait-mobile-max){
            justify-content: center;
        }
    }
    &-image {
        width: 23.6rem;
        height: 23.6rem;
        padding: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: white;
        box-shadow: 0.5rem -0.5rem 0 #9452e4;
        margin-left: 7rem;
        @include breakpoint(medium-large-desktop-max){
            width: 16.7rem;
            height: 16.7rem;
            padding: 3.5rem;
            margin-left: 4.9rem;
        }
        @include breakpoint(mobile-max){
            width: 11.8rem;
            height: 11.8rem;
            padding: 2.4rem;
            margin-left: 3.4rem;
        }
        @include breakpoint(portrait-mobile-max){
            margin-bottom: 3.4rem;
        }
        &:last-of-type{
            @media (max-width: 452px){
                margin-left: 1.7rem;
                margin-right: 1.7rem;
            }
            @include breakpoint(custom-small-mobile-max){
                margin: 0;
            }
        }
        &:first-of-type{
            margin-left: 0;
        }
    }
    .section-title {
        margin: 0 0 6.8rem 0;
        @include breakpoint(mobile-max){
            margin: 0 0 4.8rem 0;
        }
    }
}

