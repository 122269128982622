header{
    margin-top: 5.6rem; 
    margin-bottom: 2.25rem;
    position: relative;
    &.inner-header{
        .purple-inner-wave{
            display: block;
            position: absolute;
            top: -36rem;
            right: 0rem;
            width: 132.5rem;
            height: 63.7rem;
            background-image: url("../images/banner2-top.svg");
            background-repeat: no-repeat;
            background-size: cover;
            transform: rotate(-10deg);
            z-index: -1;
            @include breakpoint(medium-large-desktop-max){
                width: 94.1rem;
                height: 45.2rem;
                top: -25rem;
            }
            @include breakpoint(mobile-max){
                // top: -31.5rem;
            }
        }
    }
    .purple-home-wave{
        display: block;
        position: absolute;
        top: -18rem;
        right: 0;
        width: 132.5rem;
        height: 63.7rem;
        background-image: url("../images/shapes/banner-top.svg");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        @include breakpoint(medium-large-desktop-max){
            width: 94.1rem;
            height: 45.2rem;
            top: -13rem;
        }
        @include breakpoint(mobile-max){
            top: -31.5rem;
        }
    }
    .navbar{
        position: relative;
        padding: 2.6rem;
        background-color: $white;
        border-radius: 1.6rem;
        box-shadow: 0 0.6rem 2.4rem 0 rgba($color: #000000, $alpha: 0.16);
        @include breakpoint(medium-large-desktop-max){
            padding: 1.8rem;
        }
        .navbar-brand{
            padding: 0;
            @include breakpoint(medium-large-desktop-max){
                max-width: 16.3rem;
            }
        }
        .navbar-toggler{
            border: unset;
            display: flex;
            flex-direction: column;
            @include breakpoint(desktop-min){
               display: none;
            }
            &[aria-expanded="true"]{
                .line{
                    &:nth-child(2){
                       opacity: 0;
                    }
                    &:nth-child(1){
                        transform: translateY(7px) rotate(45deg);
                    }
                    &:nth-child(3){
                        transform: translateY(-7px) rotate(-45deg);
                    }
                }
            }
            .line{
                width: 2.2rem;
                height: 0.3rem;
                background-color: $black;
                display: block;
                margin: 0.2rem 0;
                transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
            }
        }
        .navbar-collapse{
            justify-content: flex-end;
            @include breakpoint(ipad-max){
                width: 100%;
                position: absolute;
                top: calc(100% + 1rem);
                left: 0;
                background-color: $white;
                box-shadow: 0 0.6rem 2.4rem 0 rgba($color: #000000, $alpha: 0.16);
                border-radius: 1.6rem;
                z-index: 1;
            }
            .navbar-nav{
                align-items: flex-start;
                padding: 1.8rem;
                @include breakpoint(desktop-min){
                    align-items: center;
                    padding: 0;
                }
                .nav-item{
                    font-family: 'Montserrat';
                    font-weight: 500;
                    @include breakpoint(ipad-max){
                        margin-bottom: 1.5rem;
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                    &.active{
                        font-weight: bold;
                        .nav-link{
                            &::after{
                                width: 60%;
                            }
                        }
                    }
                    .nav-link{
                        padding: 0;
                        margin-right: 4.8rem;
                        font-size: 1.8rem;
                        color: #1D2340;
                        position: relative;
                        @include breakpoint(medium-large-desktop-max){
                            margin-right: 3.3rem;
                            font-size: 1.3rem;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            top: calc(100% + 0.4rem);
                            left: 20%;
                            width: 0;
                            height: 0.4rem;
                            border-radius: 0.3rem;
                            background-color: $common-blue-color;
                            transition: all 0.5s ease-in-out;
                            @include breakpoint(ipad-max){
                                left: 0;
                            }
                        }
                        &:hover{
                            &::after{
                                width: 60%;
                            }
                        }
                    }
                    .btn{
                        margin-right: 2.8rem;
                        @include breakpoint(medium-large-desktop-max){
                            margin-right: 1.2rem;
                        }
                        &:last-of-type{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}