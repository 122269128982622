footer{
    background-image: url("../images/shapes/footer-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 16.57rem;
    @media(min-width: 2000px){
        background-size: 161.2rem;
        background-position: 50% 0;
    }
    @include breakpoint(mobile-max){
        background-position: left 25% top;
    }
    @include breakpoint(medium-large-desktop-max){
        padding-top: 11.8rem;
    }
    .footer-top{
       img{
            @include breakpoint(medium-large-desktop-max){
                max-width: 22.44rem;
            }
       }
    }
    .footer-middle{
        margin-top: 6.88rem;
        margin-bottom: 4.42rem;
        @include breakpoint(medium-large-desktop-max){
            margin-top: 4.9rem;
            margin-bottom: 3.1rem;
        }
        .row{
            [class*="col"]{
                @include breakpoint(mobile-max){
                    padding-bottom: 4rem;
                    &:last-of-type{
                        padding-bottom: 0;
                    }
                }
            }
        }
        .footer-content{
            h4{
                font-family: $secondary-font;
                font-size: 2.1rem;
                font-weight: 500;
                color: $white;
                margin-bottom: 3.3rem;
                @include breakpoint(medium-large-desktop-max){
                    font-size: 1.5rem;
                }
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    bottom: -0.52rem;
                    left: 0;
                    width: 3.6rem;
                    height: 0.4rem;
                    background-color: $common-blue-color;
                }
            }
            ul{
                li{
                    p, a{
                        font-size: 1.6rem;
                        font-weight: 300;
                        color: $white;
                        transition: all 0.5s ease;
                        @include breakpoint(medium-large-desktop-max){
                            font-size: 1.1rem;
                        }
                    }
                    a{
                        &:hover{
                            color: #9999DD;
                        }
                    }
                }
            }
            .footer-contact-info-list{
                max-width: 27.2rem;
                li{
                    display: flex;
                    margin-bottom: 4.03rem;
                    @include breakpoint(medium-large-desktop-max){
                        margin-bottom: 2.8rem;
                    }
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    .fa-icon{
                        margin-right: 1.4rem;
                        font-size: 2.4rem;
                        color: $footer-text1;
                        @include breakpoint(medium-large-desktop-max){
                            font-size: 1.7rem;
                        }
                    }
                    .contact-info{
                        color: $white;
                        h6{
                            font-size: 1.6rem;
                            font-weight: 500;
                            font-family: $secondary-font;
                            color: $footer-text1;
                            @include breakpoint(medium-large-desktop-max){
                                font-size: 1.1rem;
                            }
                        }
                        p{
                            margin-bottom: 0.1rem;
                        }
                    }
                }
            }
            .footer-quick-links-list{
                li{
                    margin-bottom: 3.1rem;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    @include breakpoint(medium-large-desktop-max){
                        margin-bottom: 2.2rem;
                    }
                    a{
                        display: inline-flex;
                        align-items: center;
                        .fa-icon{
                            color: $footer-text1;
                            margin-right: 1.3rem;
                        }
                    }
                }
            }
            .footer-social-list{
                display: flex;
                margin-bottom: 3.3rem;
                li{
                    margin-right: 3.3rem;
                    @include breakpoint(medium-large-desktop-max){
                        margin-right: 2.3rem;
                    }
                    &:last-of-type{
                        margin-right: 0;
                    }
                    a{
                        width: 4.8rem;
                        height: 4.8rem;
                        background-color: $white;
                        border-radius: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.5s ease;
                        @include breakpoint(medium-large-desktop-max){
                            width: 3.4rem;
                            height: 3.4rem;
                        }
                        &:hover{
                            box-shadow: 0 0.6rem 2.4rem 0 rgba($color: $white, $alpha: 0.5);
                        }
                        .fa-icon{
                            font-size: 2.4rem;
                            color: $common-blue-color;
                            @include breakpoint(medium-large-desktop-max){
                                font-size: 1.7rem;
                            }
                        }
                    }
                }
            }
            > p{
                color: $white;
                a{
                    text-decoration: underline;
                    color: $white;
                    transition: all 0.5s ease;
                    &:hover{
                        color: rgba($color: $white, $alpha: 0.5);
                    }
                }
            }
        }
    }
    .footer-bottom{
        background-color: $footer-bottom;
        padding: 1.4rem 0;
        @include breakpoint(medium-large-desktop-max){
            padding: 1rem 0;
        }
        p{
            margin-bottom: 0;
            color: $white;
            text-align: center;
            font-weight: 500;
            font-size: 1.4rem;
            @include breakpoint(mobile-max){
                font-size: 1.1rem;
            }
        }
    }
}